import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../../components/layout";
import Banner from "../../components/banner";
import IndividualPlan from "../../components/individualPlan";
import NumbersWithCount from "../../components/numbersWithCount";
import RecentNews from "../../components/recentNews";
import Slider from "../../components/slider";

import Image from "../../assets/img/hero-img.png";

const IndexPage = ({ data: lastPosts }) => {
  const lang = "de";

  return (
    <Layout
      seoTitle="Strona główna DE"
      isHome={true}
      lang={lang}
      translationPL="/"
      translationEN="/en/"
    >
      <Banner
        type="home"
        title="Lieferung von Paletten <br /> innerhalb von Europa immer pünktlich vor Ort "
        image={Image}
        lang={lang}
      />
      <IndividualPlan
        title="Individuelle Produktionspläne"
        text="Das Unternehmen PalettenWerk bietet Dienstleistungen im Bereich Entwicklung und Herstellung von Sonderpaletten an, hier auch von großformatigen Paletten, Plattformen und Holzplattformen für den Transport von Maschinen oder Haushaltsgeräten sowie Paletten, die besondere Bedingungen hinsichtlich der Strukturfestigkeit, Ladungssicherungssysteme und aller anderen Details der Herstellung einzelner Palettenkomponenten erfüllen. "
        lang={lang}
      />
      <section className="offer">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-4">
              <h2 className="color--main mb-40">Angebot</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-7 col-xxl-8 offset-lg-6 offset-xl-5 offset-xxl-4">
              <p className="mb-80">
                Das Angebot von PalettenWerk umfasst EPAL Europaletten, CP
                Chemiepaletten und mehr als 5 Tausend Vier- und Zwei-Wege
                Palettenmuster, die nach technischen Normen oder
                Kundenspezifikationen entworfen werden. PalettenWerk liefert
                Paletten an Unternehmen überwiegend aus der Lebensmittel-, Bau-,
                Chemie-, Pharma-, Brauerei- und Metallindustrie.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 offer__btn-holder">
              <Link className="btn" to="/de/angebot/" data-hover="Mehr sehen">
                Mehr sehen
                <span className="btn-arrows"></span>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <NumbersWithCount lang={lang} />
      <RecentNews lang={lang} lastPosts={lastPosts} />
      <Slider lang={lang} />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    lastPosts: allWpPost(
      sort: { fields: date, order: DESC }
      filter: { language: { code: { eq: DE } } }
      limit: 3
    ) {
      edges {
        node {
          id
          slug
          uri
          title
          content
          excerpt
          date
          language {
            code
            slug
            name
          }
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    }
  }
`;
